
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import PRICE from '@/modules/common/modules/rates/constants/price.enum';
import CustomTooltip, { TOOLTIP_DIRECTION } from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import { DayRateTrendsInsightData } from '../../types';
import InsightDayRateTrendModel from '../../models/insight-day-rate-trend.model';
import InsightsService, { InsightsServiceS } from '../../insights.service';

@Component({
    components: {
        CustomTooltip,
    },
})
export default class DayRateTrendsTable extends Vue {
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @Inject(InsightsServiceS) private insightsService!: InsightsService;

    hint: HTMLElement | null = null;
    hintBoundings = {
        x: 0,
        y: 70,
    };
    tooltipDirection = TOOLTIP_DIRECTION.RIGHT;

    handleHover(e: MouseEvent) {
        this.hint = e.type === 'mouseover'
            ? e.currentTarget as HTMLElement
            : null;
    }

    get hintData() {
        if (!this.dayData) {
            return {};
        }

        if (!this.hint) {
            return [[], [], []];
        }

        const index = parseInt(this.hint.dataset.index as string, 10);
        const data = this.dayData[index];

        return Object.entries<string | number | null>(data.filters);
    }

    get rawData() {
        return this.insightsService.dayData as InsightDayRateTrendModel | null;
    }

    get dayData() {
        if (!this.rawData?.data || !this.insightsService.dayGroup || !this.insightsService.dayInsightId) {
            return [];
        }

        return Object.entries<DayRateTrendsInsightData>(this.rawData.data).map(([id, hotel]) => ({
            id,
            name: hotel.name,
            price: hotel.price,
            prevPrice: hotel.prevPrice,
            currency: hotel.currency,
            prevCurrency: hotel.prevCurrency,
            isUserHotel: this.$route.params.hotelId === id,
            isInsightHotel: this.insightsService.dayGroup!.insights[this.insightsService.dayInsightId!].fornovaId === parseInt(id, 10),
            diff: hotel.price && hotel.prevPrice ? Math.round((hotel.price - hotel.prevPrice) / hotel.prevPrice * 100) : null,
            filters: {
                [this.$tc('titles.roomName')]: hotel.filters.roomName || '',
                [this.$tc('titles.mealType')]: this.$tc(this.mealTypesService.getMealType(hotel.filters.mealTypeId)?.displayName || ''),
                [this.$tc('titles.priceType')]: this.$tc(`price.${hotel.filters.priceType || ''}`),
            },
        }));
    }

    formatPrice(price: number | null, currency: string | null) {
        if (price === null || !currency) {
            return this.$tc('noData');
        }

        switch (price) {
            case PRICE.SOLD_OUT:
                return this.$tc('soldOut');
            case PRICE.NA:
                return this.$tc('na');
            default:
                return this.currencySymbol(currency) + price;
        }
    }

    currencySymbol(rawCurrency: string) {
        return this.helperService.currencySymbol(rawCurrency) || rawCurrency;
    }
}
