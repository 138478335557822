
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import PRICE from '@/modules/common/modules/rates/constants/price.enum';
import CustomTooltip, { TOOLTIP_DIRECTION } from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import { DayDeviceInsightData } from '../../types';
import InsightDayDeviceModel from '../../models/insight-day-device.model';
import InsightsService, { InsightsServiceS } from '../../insights.service';

@Component({
    components: {
        CustomTooltip,
    },
})
export default class DayDeviceTable extends Vue {
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;
    @Inject(InsightsServiceS) private insightsService!: InsightsService;

    hint: HTMLElement | null = null;
    hintBoundings = {
        x: 0,
        y: 70,
    };
    tooltipDirection = TOOLTIP_DIRECTION.RIGHT;

    handleHover(e: MouseEvent) {
        this.hint = e.type === 'mouseover'
            ? e.currentTarget as HTMLElement
            : null;
    }

    get hintData() {
        if (!this.dayData) {
            return {};
        }

        if (!this.hint) {
            return [[], [], []];
        }

        const index = parseInt(this.hint.dataset.index as string, 10);
        const data = this.dayData[index];

        return Object.entries<string | number | null>(data.filters);
    }

    get rawData() {
        return this.insightsService.dayData as InsightDayDeviceModel | null;
    }

    get dayData() {
        if (!this.rawData?.data) {
            return [];
        }

        return Object.entries<DayDeviceInsightData>(this.rawData.data).map(([id, hotel]) => ({
            id,
            name: hotel.name,
            price: hotel.price,
            comparePrice: hotel.comparePrice,
            currency: hotel.currency,
            compareCurrency: hotel.compareCurrency,
            diff: hotel.price && hotel.comparePrice ? Math.round((hotel.price - hotel.comparePrice) / hotel.comparePrice * 100) : null,
            filters: {
                [this.$tc('titles.roomName')]: hotel.filters.roomName || '',
                [this.$tc('titles.mealType')]: this.$tc(this.mealTypesService.getMealType(hotel.filters.mealTypeId)?.displayName || ''),
                [this.$tc('titles.priceType')]: this.$tc(`price.${hotel.filters.priceType || ''}`),
            },
        }));
    }

    get oppositeProvider() {
        if (!this.rawData?.filters) {
            return '';
        }

        return this.providersService.getProviderLabel(this.rawData.filters.oppositeProvider as string);
    }

    get provider() {
        if (!this.rawData?.filters) {
            return '';
        }

        const oppositeProvider = this.rawData.filters.oppositeProvider as string;
        const provider = /mobile_app/.test(oppositeProvider)
            ? oppositeProvider.replace('_mobile_app', '')
            : `${oppositeProvider.replace('_cug', '')}_mobile_app`;

        return this.providersService.getProviderLabel(provider);
    }

    isMyHotel(id: string | number) {
        return this.$route.params.hotelId === String(id);
    }

    formatPrice(price: number | null, currency: string | null) {
        if (price === null || !currency) {
            return this.$tc('noData');
        }

        switch (price) {
            case PRICE.SOLD_OUT:
                return this.$tc('soldOut');
            case PRICE.NA:
                return this.$tc('na');
            default:
                return this.currencySymbol(currency) + price;
        }
    }

    currencySymbol(rawCurrency: string) {
        return this.helperService.currencySymbol(rawCurrency) || rawCurrency;
    }
}
