
import { Component, Vue } from 'vue-property-decorator';
import PromotionsService, { PromotionsServiceS } from '@/modules/promotions/promotions.service';
import { Inject } from 'inversify-props';
import InsightDayMarketModel from '../../models/insight-day-market.model';
import type { DayMarketInsightData } from '../../types';
import InsightsService, { InsightsServiceS } from '../../insights.service';

@Component
export default class DayMarketsTable extends Vue {
    @Inject(PromotionsServiceS) private promotionsService!: PromotionsService;
    @Inject(InsightsServiceS) private insightsService!: InsightsService;

    get rawData() {
        return this.insightsService.dayData as InsightDayMarketModel | null;
    }

    get dayData() {
        if (!this.rawData?.data) {
            return [];
        }

        return Object.entries<DayMarketInsightData>(this.rawData.data).map(([id, hotel]) => ({
            id,
            name: hotel.name,
            page: hotel.page,
            position: hotel.position,
            promotions: hotel.promotions?.filter(p => p.data.status).map(p => p.name) || [],
        }));
    }

    isMyHotel(id: string | number) {
        return this.$route.params.hotelId === String(id);
    }

    getProgramLogo(program: string) {
        return this.promotionsService
            .getProgramLogo(program);
    }
}
